import styled from "styled-components";
import { useState, lazy, Suspense, useMemo, useEffect, useRef } from "react";
import { urlPaths } from "../../utils/config";
import ReactPlayer from 'react-player';
import { validateSession } from "../../hooks/useValidateSession";
import { useGetStoredData } from "../../hooks/useGetStoreData";
import { useNavigate } from "react-router-dom";

import Videotutorial from "../../components/Videotutorial";
import Video from "../../assets/section-one-video.png";
import { size } from "../../utils/helpers";

const Tutorial = () => {
  const [playingVideoIdx, setPlayingVideoIdx] = useState(null);
  const navigate = useNavigate();
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);

  useEffect(() => {
    validateSession()
      .then((result) => {
         console.log('VALIDATIONRESULT', result)
        if (result.success === false || result === false) {
          navigate("/unauthorized");
        }
      })
      .catch((error) => {
        navigate("/unauthorized");
      });
  }, [navigate, storageJson]);

  const handlePlay = (idx) => {
    setPlayingVideoIdx(idx);
  };

  return (
    <Section>
      <Container>
        <h1>Commenter AI Training Videos</h1>
        <WrapperContent>
          {[{
            video: "https://www.youtube.com/embed/dR2mLJhTAZA?si=WbxgY3U4Dy-e7eOq",
            description: "How to connect Commenter AI with Social Post Mate"
          }, {
            video: "https://www.youtube.com/embed/T4cpvA0CDfw?si=2Q_yB43Q_m_Sfeba",
            description: "How to install Social Post Mate"
          }, {
            video: "https://www.youtube.com/embed/2yeKWnus2A4?si=cr6Q3sCx-Rec24A5",
            description: "Commenter AI Pop Up - Features"
          },{
            video: "https://www.youtube.com/embed/3lHjEncEQR8?si=39rw6X9YWWTFQmok",
            description: "How to set up custom tone feature"
          },
          {
            video: "https://www.youtube.com/embed/Z454gyMDZfg?si=0R-a2QsabljtGFPo",
            description: "Three Ways to Generate Comments with Commenter AI"
          },{
            video: "https://www.youtube.com/embed/jyQbBVjAoHU?si=jvcTEkL515O6R_5I",
            description: "What each menu button means"
          },{
            video: "https://www.youtube.com/embed/Pveba540C_A?si=b-1rYmkDsbfDgRnO",
            description: "Strategic commenting - Video 1"
          },{
            video: "https://www.youtube.com/embed/0IFAX7wNNqg?si=sdCGln8b2FUwqlo9",
            description: "Strategic commenting - video 2"
          }].map((item, idx) => (
            <VideoWrapper key={idx}>
              <ImageDiv>
                <Videotutorial
                  img={Video}
                  video={item.video}
                  idx={idx}
                  isPlaying={playingVideoIdx === idx}
                  onPlay={handlePlay}
                />
              </ImageDiv>
              <p>{item.description}</p>
            </VideoWrapper>
          ))}
        </WrapperContent>
      </Container>
    </Section>
  );
};

const Section = styled.div`
  width: 100%;
  background: #0050b2;
  padding: 80px 16px;
  box-sizing: border-box;

  @media only screen and (max-width:767px){
     padding: 40px 16px;
  }

`;
const Container = styled.div`
  max-width: 1295px;
  box-sizing: border-box;
  margin: auto;
  > h1 {
    margin: 0px 0px 40px;
    text-align: center;
    font-size: 32px;
    color:#fff;

    @media only screen and (max-width:767px){
      margin: 0px 0px 20px;
      font-size: 28px;
  }
  }
`;
const WrapperContent = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 40px;

    @media only screen and (max-width:1024px){
       grid-template-columns: repeat(2, 1fr);
       gap: 30px;
    }
    @media only screen and (max-width:767px){
       grid-template-columns: repeat(1, 1fr);
       gap: 20px;
    }
`;
const VideoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width:100%;
  
  >p{
    text-align: center;
    color: #fff;
    margin: 10px 0 0;
    font-size: 22px;
  }
`;
const VideoSection = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 1700px;
`;
const ImageDiv = styled.div`
    width: 100%;
`;
export default Tutorial;
