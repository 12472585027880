import { lazy, Suspense, useRef } from "react";
import styled from "styled-components";
import { Title } from "../../components/Title";
import { MainContainer } from "../../components/mainContainer";
import { size } from "../../utils/helpers";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { validateSession } from "../../hooks/useValidateSession";
import { fetchAPI } from "../../utils/fetch";
import { useState } from "react";
import { USER_SUBSCRIPTION, config } from "../../utils/config";
import { useGetStoredData } from "../../hooks/useGetStoreData";
import { Loader } from "../../components/loader";
import checkIcon from "../../assets/modal-check-icon.png";
import RedLinkButton from "../../components/RedLinkButton";
import { urlPaths } from "../../utils/config";
import { useDebugValue } from "react";

const AccountForm = lazy(() => import("../../features/AccountForm"));
const PluginSection = lazy(() => import("../../layout/platform/PluginSection"));
const StartGrowingList = lazy(() =>
  import("../../features/StartGrowingContent")
);

const SettingsPage = () => {
  const [isPlanModalOpen, setPlanModalOpen] = useState(false);
  const [isChecked, setIsChecked] = useState(true);

  const handleToggleChange = () => {
    setIsChecked(!isChecked);
  };


  useEffect(() => {
    // Add an event listener to disable body scroll when the modal is open
    const handleBodyScroll = (e) => {
      if (isPlanModalOpen) {
        e.preventDefault();
      }
    };

    document.body.style.overflowY = isPlanModalOpen ? "hidden" : "auto";
    document.body.addEventListener("touchmove", handleBodyScroll, {
      passive: false,
    });

    return () => {
      // Clean up the event listener when the component unmounts
      document.body.style.overflowY = "auto";
      document.body.removeEventListener("touchmove", handleBodyScroll);
    };
  }, [isPlanModalOpen]);

  const openPlanModal = () => setPlanModalOpen(true);
  const closePlanModal = () => setPlanModalOpen(false);

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [trialStart, setTrialStart] = useState("");
  const [trialEnd, setTrialEnd] = useState("");
  const [subscriptionData, setSubscriptionData] = useState([]);
  const [commentsLeft, setCommentsLeft] = useState(0);
  const [email, setEmail] = useState("");
  const [isLoadingOne, setLoadingOne] = useState(false);
  const [isLoadingThree, setLoadingThree] = useState(false);
  const [isLoadingPrm, setLoadingPrm] = useState(false);
  const [isLoadingPro, setLoadingPro] = useState(false);
  const [stripeId, setStripeId] = useState("");
  const [status, setStatus] = useState("");
  const storedData = useGetStoredData();
  const [showProfessionalBanner, setShowProfessionalBanner] = useState(false);
  const [showProfessionalBannerYearly, setShowProfessionalBannerYearly] = useState(false);
  const subscriptionId = localStorage.getItem("subscriptionId");
  const subscriptionStatus = localStorage.getItem("subscription");
  const userDetails = JSON.parse(localStorage.getItem("userData"));
  const [userDetail, setUserDetail] = useState(userDetails);

  const [checkboxClicked, setCheckboxClicked] = useState(false);

  const navigate = useNavigate();
  var intervalId;
  useEffect(() => {
    validateSession()
      .then((result) => {
        if (result.success === false || result === false) {
          //navigate("/unauthorized");
        }
      })
      .catch((error) => {
        //navigate("/unauthorized");
      });
  }, [navigate]);

  useEffect(() => {
    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storedData.userData.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if ("success" in result) {
          setErrorMessage(result.message);
        } else {
          if (result.data?.subscriptionData) {
            setTrialStart(
              new Date(
                Number(result.data?.subscriptionData?.[0]?.trial_start + "000")
              ).toDateString() +
              " " +
              new Date(
                Number(result.data.subscriptionData[0]?.trial_start + "000")
              ).toLocaleTimeString()
            );
            setTrialEnd(
              new Date(
                Number(result.data?.subscriptionData?.[0]?.trial_end + "000")
              ).toDateString() +
              " " +
              new Date(
                Number(result.data.subscriptionData[0]?.trial_end + "000")
              ).toLocaleTimeString()
            );
          }
          localStorage.setItem(
            "subscriptionId",
            result.data.subscriptionData?.[0]?.id
          );
          localStorage.setItem("commentsLeft", result.data.commentsLeft);
          localStorage.setItem("status", result.data.status);
          setSubscriptionData(result.data.subscriptionData);
          setCommentsLeft(result.data.commentsLeft);
          setEmail(result.data.email);
          setStripeId(result.data.stripeId);
          setStatus(result.data.status);
        }
      })
      .catch((error) => {
        setErrorMessage("We had issue with the server");
      });
  }, [storedData.userData.email]);

  const handleCancelSubscription = () => {
    setLoadingOne(true);
    fetchAPI
      .put(
        `${config.urlPlatform}/api/v2/payment/cancel-subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: subscriptionId,
        }
      )
      .then((response) => {
        console.log("response cancel sub >>> ", response);
        setLoadingOne(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          setSuccessMessage("Successfully canceled subscription!");
          localStorage.setItem(
            "userData",
            JSON.stringify(response?.data?.userData)
          );
          setUserDetail(response?.data?.userData);
          localStorage.setItem("subscription", "canceled");
          openModal();
        }
      })
      .catch((error) => {
        setLoadingOne(false);
        setErrorMessage(
          "There was an issue canceling your subscription. Please contact us!"
        );
      });
  };

  const handleClick = () => {
    if (checkboxClicked) {
      handleCancelSubscription();
    }
  };

  useEffect(() => {
    if (userDetail?.plan !== "proMonth" || userDetail.status === 'canceled') {
      if ((userDetail?.plan === "professional" && userDetail?.paidStatus === true)) {
        setShowProfessionalBanner(false)
      } else {
        setShowProfessionalBanner(true);
      }
    }
  }, [userDetail?.plan, userDetail?.paidStatus])

  useEffect(() => {
    if (userDetail?.plan !== "proMonth_yearly" || userDetail.status === 'canceled') {
      if ((userDetail?.plan === "professional_yearly" && userDetail?.paidStatus === true)) {
        setShowProfessionalBannerYearly(false)
      } else {
        setShowProfessionalBannerYearly(true);
      }
    }
  }, [userDetail?.plan, userDetail?.paidStatus])

  const handleUpgradePlan = () => {
    fetchAPI
      .put(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: subscriptionId,
        }
      )
      .then((response) => {
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setErrorMessage(
          "There was an issue trying to upgrade your plan. Please contact us!"
        );
      });
  };

  const handleSubscribe = () => {
    setLoadingThree(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: 'starter',
        }
      )
      .then((response) => {
        setLoadingThree(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          //setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingThree(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribePrm = () => {
    console.log({
      email: email,
      subscriptionId: stripeId,
      plan: "proMonth",
    });
    setLoadingPrm(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "proMonth",
        }
      )
      .then((response) => {
        setLoadingPrm(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPrm(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribePro = () => {
    setLoadingPro(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "professional",
        }
      )
      .then((response) => {
        setLoadingPro(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPro(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };


  const handleSubscribeYearly = () => {
    setLoadingThree(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "starter_yearly",
        }
      )
      .then((response) => {
        setLoadingThree(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingThree(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribeProfesYearly = () => {
    
    setLoadingPro(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "professional_yearly",
        }
      )
      .then((response) => {
        setLoadingPro(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPrm(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const handleSubscribeProYearly = () => {
    setLoadingPrm(true);
    fetchAPI
      .post(
        `${config.urlPlatform}/api/v2/payment/subscription`,
        {
          withCredentials: true,
        },
        {
          email: email,
          subscriptionId: stripeId,
          plan: "proMonth_yearly",
        }
      )
      .then((response) => {
        setLoadingPrm(false);
        if (response.status !== 200) {
          throw new Error("error");
        }
        if (response.data.success === false) {
          setErrorMessage(response.data.message);
        } else {
          window.location.replace(response.data.url);
          setSuccessMessage("Successfully upgraded subscription!");
          localStorage.setItem("subscription", "pro");
        }
      })
      .catch((error) => {
        setLoadingPro(false);
        setErrorMessage("There was an issue subscribing. Please contact us!");
      });
  };

  const openModal = () => {
    const popup = document.getElementsByClassName("responster-popup")[0];
    const overlay = document.getElementsByClassName(
      "responster-popup-overlay"
    )[0];
    if (popup && overlay) {
      popup.style.display = "block";
      overlay.style.display = "block";
    }
  };

  const closeModal = () => {
    const popup = document.getElementsByClassName("responster-popup")[0];
    const overlay = document.getElementsByClassName(
      "responster-popup-overlay"
    )[0];
    if (popup && overlay) {
      popup.style.display = "none";
      overlay.style.display = "none";
    }
    window.location.reload();
  };
  var count = 0;
  const closeDelay = () => {
    count++;
    if (count === 2)
      setTimeout(() => {
        closeModal();
      }, 3000);
  };
  //console.log("user plan>>>>", userDetail);
  return (
    <Wrapper>
      <MainContainer>
        <TwoColumns>
          <LeftColumn>
            <Title>Settings</Title>
            {errorMessage ? <Error>{errorMessage}</Error> : null}
            {successMessage ? <Success>{successMessage}</Success> : null}
            <Suspense
              fallback={
                <div
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Loading...
                </div>
              }
            >
              <AccountForm
                email={email}
                errorMessage={errorMessage ?? null}
                trialStart={trialStart ?? null}
                trialEnd={trialEnd ?? null}
                subscriptionData={subscriptionData ?? []}
                commentsLeft={commentsLeft ?? 0}
                status={status}
              />
            </Suspense>
            <Suspense
              fallback={
                <div
                  style={{
                    minHeight: "100vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Loading...
                </div>
              }
            >
              <PluginSection email={email} />
              {status === USER_SUBSCRIPTION.ACTIVE ||
                status === USER_SUBSCRIPTION.PAST_DUE ? (
                <BoxContent isButton className="block">
                  <SubmitButton
                    className={
                      isLoadingOne ? "loading cancel block" : "cancel block"
                    }
                    onClick={handleClick}
                    // onClick={handleCancelSubscription}
                    // disabled={!checkboxClicked}
                    style={{ cursor: checkboxClicked ? 'pointer' : 'not-allowed', pointerEvents: isLoadingOne ? 'none' : 'auto' }}

                  >
                    {isLoadingOne ? <Loader /> : "CANCEL SUBSCRIPTION"}
                  </SubmitButton>
                  <Checkoutbox>
                    <form className="checkform">
                      <label className="checkoutlable">
                        <input
                          onClick={() => setCheckboxClicked(!checkboxClicked)}
                          type="checkbox"
                          id="cancelsub"
                          name="cancelsub"
                          value="cancelsub"
                        />
                        I understand that my comment credits will be lost when my subscription expires.                      </label>
                    </form>
                  </Checkoutbox>

                  {/* {(userDetail.plan === 'starter' || userDetail.plan === 'professional') && !userDetail.isFreeTrial &&
                  <UpgradeButton
                      className={isLoadingThree ? "loading block" : "block"}
                    
                    >
                      {isLoadingThree ? <Loader /> : "UPGRADE MY PLAN"}
                    </UpgradeButton>} */}
                  {userDetail.plan === "proMonth" &&
                    userDetail.paidStatus === true
                    ? ""
                    : (userDetail.plan === "starter" ||
                      userDetail.plan === "professional" ||
                      userDetail.plan === "starter_yearly" ||
                      userDetail.plan === "professional_yearly") &&
                    userDetail.paidStatus && (
                      <UpgradeButton
                        onClick={openPlanModal}
                        className={isLoadingThree ? "loading block" : "block"}
                      >
                        {isLoadingThree ? <Loader /> : "UPGRADE MY PLAN"}
                      </UpgradeButton>
                    )}
                </BoxContent>
              ) : (
                ""
              )}
              {status !== USER_SUBSCRIPTION.TRIAL &&
                status !== USER_SUBSCRIPTION.ACTIVE ? (
                <BoxContent isButton className="block">
                  {/* <SubmitButton className={isLoadingThree  ? "loading block" : "block"} onClick={handleSubscribe}>{ isLoadingThree ? <Loader /> : "SUBSCRIBE" }</SubmitButton> */}
                  {!userDetail.paidStatus && (
                    <SubmitButton
                      className={isLoadingThree ? "loading block" : "block"}
                      onClick={openPlanModal}
                    >
                      {isLoadingThree ? <Loader /> : "SUBSCRIBE"}
                    </SubmitButton>
                  )}
                {
                  ["starter", "professional", "starter_yearly", "professional_yearly"].includes(userDetail.plan) &&
                  userDetail.paidStatus && (
                    <UpgradeButton
                      className={`${isLoadingThree ? "loading block" : "block"}`}
                    >
                      {isLoadingThree ? <Loader /> : "UPGRADE MY PLAN"}
                    </UpgradeButton>
                  )
                }

                </BoxContent>
              ) : (
                ""
              )}
              <div>
                {isPlanModalOpen && (
                  <div className="modalOverlay" onClick={closePlanModal}>
                    <div
                      className="modalContent"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <div className="modalHeader">
                        <span className="closeButton" onClick={closePlanModal}>
                          &times;
                        </span>
                      </div>
                      <Toggle>
                          <div className="toggle-container">
                          <label className="toggle-label">Monthly</label>
                            <label className="toggle">
                              <input
                                type="checkbox"
                                checked={isChecked}
                                onChange={handleToggleChange}
                              />
                              <span className="slider"></span>
                            </label>
                            <label className="toggle-label">Yearly (up to 30% OFF)</label>
                          </div>
                      </Toggle>
                      {isChecked ? (
                    <>
                    <div className="planWrapperDiv">
                {(
                  (userDetail?.plan === "starter_yearly" && userDetail?.paidStatus !== true) || 
                  userDetail.status === "canceled" || 
                  ["starter", "professional", "proMonth"].includes(userDetail?.plan) && userDetail?.paidStatus
                )  && (
                  <PlanboxOne>
                    <FreeplanPart>
                      <h2>Starter plans</h2>
                    </FreeplanPart>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text> 300 comments/month</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>1 emotion</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>TextTune™ - only short comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Standard support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span>$144</span> $97/year
                      </MonthPlan>
                      <BoxContent isButton>
                        <SubmitButton
                          className={
                            isLoadingThree ? "loading" : ""
                          }
                          onClick={handleSubscribeYearly}
                        >
                          {isLoadingThree ? (
                            <Loader />
                          ) : (
                            "UPGRADE ME TO STARTER"
                          )}
                        </SubmitButton>
                      </BoxContent>
                    
                    </ContentList>
                  </PlanboxOne>
                )}

                <PlanboxTwo>
                  <ProPlan>Influencer PLAN
                    <span>(customize your prompt)</span>
                  </ProPlan>
                  {/* <Tag>Most Popular</Tag> */}

                  <ContentList>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>3000 comments/month </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>5 emotions (+Natural™ emotion)</Text>
                    </ContentItem>
                    
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Full TextTune™ - short & long comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>GenuYou™ - turn your ideas into comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Multi-language support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Grammar check of your comment</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>VIP support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Commenter academy</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>SmartReply+™ - reply to comments</Text>
                    </ContentItem>
                  
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Video course: "Strategic commenting"</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>
                        <strong>+Customize your prompt & voice</strong>
                      </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+13 commenting styles</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Saved comments - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Engagement list - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Special discounts for events, courses</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Commenting on Facebook and Twitter</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+eBook - "All-in-one LI personal branding"</strong></Text>
                    </ContentItem>
                    <MonthPlan>
                      {" "}
                      <span>$324</span> $197/year
                    </MonthPlan>
                    <PerCup>
                      (less than a cup of coffee per day)
                    </PerCup>
                    <BoxContent isButton>
                      <SubmitButton
                        className={isLoadingPrm ? "loading" : ""}
                        onClick={handleSubscribeProYearly}
                      >
                        {isLoadingPrm ? (
                          <Loader />
                        ) : (
                          "UPGRADE ME TO PREMIUM"
                        )}
                      </SubmitButton>
                    </BoxContent>
                    {/* <RedButtonclick>
                                  <RedLinkButton
                                    href={urlPaths.proplan}
                                    text="UPGRADE PREMIUM PLAN"
                                    rounded={false}
                                  />
                                </RedButtonclick> */}
                    <CancleAnytime>
                      {/* <TextCancel>
                                    <CheckIcon
                                      style={{ width: "13px", height: "13px" }}
                                      src={checkIcon}
                                    />
                                    <Text>No credit card required</Text>
                                  </TextCancel> */}
                    </CancleAnytime>
                  </ContentList>
                </PlanboxTwo>

                {showProfessionalBannerYearly && (
                  <PlanboxOne>
                    <ProPlanBlackTwo>
                      Creator PLAN
                    </ProPlanBlackTwo>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>900 comments/month </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>3 emotions</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text> Full TextTune™ - short and long comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support.</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Priority support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+SmartReply+™- basic comment replies</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+5 commenting styles</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Saved comments - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Engagement list - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Video course: “Strategic commenting”</strong></Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span>$204</span> $149/year
                      </MonthPlan>

                      {/* <MonthPlan> <span>$324</span> $243/year</MonthPlan> */}
                      <NewRedbtn>
                        <BoxContent isButton>
                          <SubmitButton
                            className={
                              isLoadingPro ? "loading" : ""
                            }
                            onClick={handleSubscribeProfesYearly}
                          >
                            {isLoadingPro ? (
                              <Loader />
                            ) : (
                              "UPGRADE ME TO PROFESSIONAL"
                            )}
                          </SubmitButton>
                        </BoxContent>
                        {/* <RedButtonclick>
                                      <RedLinkButton
                                        href="https://forms.gle/LkJZVf9eNju6s7C27"
                                        text="UPGRADE PROFESSIONAL PLAN"
                                        rounded={false}
                                      />
                                    </RedButtonclick> */}
                        {/* <CancleAnytime>
                                      <TextCancel>
                                        <CheckIcon
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          src={checkIcon}
                                        />
                                        <Text>Limited spots available</Text>
                                      </TextCancel>
                                    </CancleAnytime> */}
                      </NewRedbtn>
                    </ContentList>
                  </PlanboxOne>
                )}
                    </div>
                    </>
                    ):(<>
                    <div className="planWrapperDiv">
                    
                {(
                  (userDetail?.plan === "starter" && userDetail?.paidStatus !== true) || 
                  userDetail.status === "canceled" || 
                  ["starter_yearly", "professional_yearly", "proMonth_yearly"].includes(userDetail?.plan) && userDetail?.paidStatus
                ) &&  (
                  <PlanboxOne>
                    <FreeplanPart>
                      <h2>Starter plans</h2>
                    </FreeplanPart>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>300 comments/month</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>1 emotion</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>TextTune™ - only short comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Standard support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span></span>Just $12/month
                      </MonthPlan>
                      <BoxContent isButton>
                        <SubmitButton
                          className={
                            isLoadingThree ? "loading" : ""
                          }
                          onClick={handleSubscribe}
                        >
                          {isLoadingThree ? (
                            <Loader />
                          ) : (
                            "UPGRADE ME TO STARTER"
                          )}
                        </SubmitButton>
                      </BoxContent>
                      {/* <RedButtonclick>
                                  <RedLinkButton
                                    href={urlPaths.register}
                                    text="Upgrade Plan"
                                    rounded={false}
                                  />
                                </RedButtonclick> */}
                      {/* <NeeDed>
                                    <CheckIcon
                                      style={{ width: "13px", height: "13px" }}
                                      src={checkIcon}
                                    />
                                    No credit card required
                                  </NeeDed> */}
                    </ContentList>
                  </PlanboxOne>
                )}

                <PlanboxTwo>
                  <ProPlan>Influencer PLAN
                    <span>(customize your prompt)</span>
                  </ProPlan>
                  {/* <Tag>Most Popular</Tag> */}

                  <ContentList>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>3000 comments/month </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>5 emotions (+Natural™ emotion)</Text>
                    </ContentItem>
                    {/* <ContentItem>
                <CheckIcon src={checkIcon} />
                <Text>10 commenting styles</Text>
              </ContentItem> */}
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Full TextTune™ - short & long comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>GenuYou™ - turn your ideas into comments</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Multi-language support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Grammar check of your comment</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>VIP support</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Commenter academy</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>SmartReply+™ - reply to comments</Text>
                    </ContentItem>
                    {/* <ContentItem>
                <CheckIcon src={checkIcon} />
                <Text>Access to our exclusive community</Text>
              </ContentItem> */}
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>Video course: "Strategic commenting"</Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text>
                        <strong>+Customize your prompt & voice</strong>
                      </Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+13 commenting styles</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Saved comments - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Engagement list - unlimited</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Special discounts for events, courses</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+Commenting on Facebook and Twitter</strong></Text>
                    </ContentItem>
                    <ContentItem>
                      <CheckIcon src={checkIcon} />
                      <Text><strong>+eBook - "All-in-one LI personal branding"</strong></Text>
                    </ContentItem>
                    <MonthPlan>
                      {" "}
                      <span>$81</span> $27/month
                    </MonthPlan>
                    <PerCup>
                      (less than a cup of coffee per day)
                    </PerCup>
                    <BoxContent isButton>
                      <SubmitButton
                        className={isLoadingPrm ? "loading" : ""}
                        onClick={handleSubscribePrm}
                      >
                        {isLoadingPrm ? (
                          <Loader />
                        ) : (
                          "UPGRADE ME TO PREMIUM"
                        )}
                      </SubmitButton>
                    </BoxContent>
                    {/* <RedButtonclick>
                                  <RedLinkButton
                                    href={urlPaths.proplan}
                                    text="UPGRADE PREMIUM PLAN"
                                    rounded={false}
                                  />
                                </RedButtonclick> */}
                    <CancleAnytime>
                      {/* <TextCancel>
                                    <CheckIcon
                                      style={{ width: "13px", height: "13px" }}
                                      src={checkIcon}
                                    />
                                    <Text>No credit card required</Text>
                                  </TextCancel> */}
                    </CancleAnytime>
                  </ContentList>
                </PlanboxTwo>

                {showProfessionalBanner && (
                  <PlanboxOne>
                    <ProPlanBlackTwo>
                      Creator PLAN
                    </ProPlanBlackTwo>
                    <ContentList>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>900 comments/month </Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>3 emotions</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text> Full TextTune™ - short and long comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>GenuYou™ - turn your ideas into comments</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Multi-language support.</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Grammar check of your comment</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Priority support</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text>Commenter academy</Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+SmartReply+™- basic comment replies</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+5 commenting styles</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Saved comments - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Engagement list - up to 40.</strong></Text>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <Text><strong>+Video course: “Strategic commenting”</strong></Text>
                      </ContentItem>
                      <MonthPlan>
                        {" "}
                        <span>$36</span> $17/month
                      </MonthPlan>

                      {/* <MonthPlan> <span>$324</span> $243/year</MonthPlan> */}
                      <NewRedbtn>
                        <BoxContent isButton>
                          <SubmitButton
                            className={
                              isLoadingPro ? "loading" : ""
                            }
                            onClick={handleSubscribePro}
                          >
                            {isLoadingPro ? (
                              <Loader />
                            ) : (
                              "UPGRADE ME TO PROFESSIONAL"
                            )}
                          </SubmitButton>
                        </BoxContent>
                        {/* <RedButtonclick>
                                      <RedLinkButton
                                        href="https://forms.gle/LkJZVf9eNju6s7C27"
                                        text="UPGRADE PROFESSIONAL PLAN"
                                        rounded={false}
                                      />
                                    </RedButtonclick> */}
                        {/* <CancleAnytime>
                                      <TextCancel>
                                        <CheckIcon
                                          style={{
                                            width: "13px",
                                            height: "13px",
                                          }}
                                          src={checkIcon}
                                        />
                                        <Text>Limited spots available</Text>
                                      </TextCancel>
                                    </CancleAnytime> */}
                      </NewRedbtn>
                    </ContentList>
                  </PlanboxOne>
                )}
                    </div>
                    </>)}
                    </div>
                  </div>
                )}

                <style jsx>{`
                      .modalOverlay {
                          position: fixed;
                          top: 0;
                          left: 0;
                          width: 100%;
                          height: 100%;
                          background: rgba(0, 0, 0, 0.8);
                          display: flex;
                          align-items: start;
                          justify-content: center;
                          z-index: 99;
                          {/* padding-top: 40px; */}
                      }

                              .modalContent {
                                  background: #fff0;
                                      padding: 20px;
                                      border-radius: 8px;
                                      width: 100%;
                                      /* margin: 40px auto; */
                                      height: calc(100vh - 35px);
                                      overflow-y: auto;
                              }

                              .modalHeader {
                              display: flex;
                              justify-content: flex-end;
                              }

                              .closeButton {
                              cursor: pointer;
                              font-size: 30px;
                              color: #fff;
                              }

                              .openButton {
                              margin-top: 20px;
                              cursor: pointer;
                              }
                              .planWrapperDiv{
                                  display:flex;
                                  justify-content: center;
                                  gap: 10px;
                              }
                              ::-webkit-scrollbar {
  width: 0px;
}

                              @media only screen and (max-width:1023px){
                                  .planWrapperDiv {
                                          flex-direction: column;
                                      }
                                      .planWrapperDiv >div {
                                          width: 100%;
                                          margin: auto;
                                          max-width: 100%;
                                          box-sizing:border-box;
                                      }
                              }
                              }

                              @media only screen and (max-width:767px){
                                  .modalContent {
                                      height: 600px;
                                    }
                              }
                          `}</style>
              </div>
            </Suspense>
          </LeftColumn>
          <RightColumn>
          {userDetail.plan === "free" ||
            (userDetail.plan === "proMonth" || userDetail.plan === "proMonth_yearly") && (
                <InfoBoxTwo>
                  <BoxContent className="plan">
                    <strong>My Current Plan</strong>
                  </BoxContent>
                  <BoxTitle>
                    {status === USER_SUBSCRIPTION.TRIAL
                      ? "Trial Subscription"
                      : ""}
                    {status === USER_SUBSCRIPTION.ACTIVE
                      ? userDetail?.plan === "proMonth_yearly"
                        ? "Premium Yearly Subscription"
                        : "Premium Subscription"
                      : ""}
                    {status === USER_SUBSCRIPTION.PAST_DUE ? "Past Due" : ""}
                    {status !== USER_SUBSCRIPTION.TRIAL &&
                      status !== USER_SUBSCRIPTION.ACTIVE &&
                      status !== USER_SUBSCRIPTION.PAST_DUE
                      ? "Not subscribed"
                      : ""}
                  </BoxTitle>
                  {status === USER_SUBSCRIPTION.TRIAL ||
                    status === USER_SUBSCRIPTION.ACTIVE ? (
                      <BoxContent>
                      {userDetail?.plan === "proMonth_yearly" ? (
                        <>
                          <PriceSpan>$197</PriceSpan> per year
                        </>
                      ) : (
                        <>
                          <PriceSpan>$27</PriceSpan> per month
                        </>
                      )}
                    </BoxContent>
                  ) : (
                    ""
                  )}
                  {status !== USER_SUBSCRIPTION.TRIAL &&
                    status !== USER_SUBSCRIPTION.ACTIVE ? (
                    <BoxContent>
                    {userDetail?.plan === "proMonth_yearly" ? (
                        <>
                          <PriceSpan>$0</PriceSpan> per year
                        </>
                      ) : (
                        <>
                          <PriceSpan>$0</PriceSpan> per month
                        </>
                      )}
                    </BoxContent>
                  ) : (
                    ""
                  )}
                  <BoxContent style={{ margin: "-35px" }}>
                    <Suspense
                      fallback={
                        <div
                          style={{
                            minHeight: "100vh",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          Loading...
                        </div>
                      }
                    >
                      {/* <StartGrowingList /> */}
                      <ContentListBox>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>3000 comments/month</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>6 emotions (+Natural™ emotion)</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>10 commenting styles</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            Full TextTune™ - short/long comments
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>GenuYou™ - personalized comments</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Multi-language support</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            SmartReply+™ - advanced comment replies
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Access to our exclusive community</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Personalized commenting strategy</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Saved comments - unlimited</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Engagement list - unlimited</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Grammar check of your comment</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Stand out comment suggestion</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>Commenter academy</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>30% referral commission</TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            Special discounts for events, courses
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            Commenting on Facebook and Twitter
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            eBook - "All-in-one LI personal branding"
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>
                            Video course: "Strategic commenting"
                          </TextBoxs>
                        </ContentItem>
                        <ContentItem>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>VIP support</TextBoxs>
                        </ContentItem>
                      </ContentListBox>
                    </Suspense>
                  </BoxContent>
                  {status === USER_SUBSCRIPTION.TRIAL ? (
                    <BoxContent isButton>
                      <SubmitButton
                        onClick={userDetail?.plan === "proMonth_yearly" ? handleSubscribeProYearly : handleSubscribePrm}
                      >
                        UPGRADE MY PLAN
                      </SubmitButton>
                    </BoxContent>
                  ) : (
                    ""
                  )}
                  {/* {status === USER_SUBSCRIPTION.TRIAL ||
                    status === USER_SUBSCRIPTION.ACTIVE ||
                    status === USER_SUBSCRIPTION.PAST_DUE ? (
                    <BoxContent isButton>
                      <SubmitButton
                        className={isLoadingOne ? "loading cancel" : "cancel"}
                        onClick={handleCancelSubscription}
                      >
                        {isLoadingOne ? <Loader /> : "CANCEL SUBSCRIPTION"}
                      </SubmitButton>
                    </BoxContent>
                  ) : (
                    ""
                  )} */}
                  {status !== USER_SUBSCRIPTION.TRIAL &&
                    status !== USER_SUBSCRIPTION.ACTIVE ? (
                    <BoxContent isButton>
                      <SubmitButton
                        onClick={userDetail?.plan === "proMonth_yearly" ? handleSubscribeProYearly : handleSubscribePrm}
                      >
                        {isLoadingThree ? <Loader /> : "SUBSCRIBE"}
                      </SubmitButton>
                    </BoxContent>
                  ) : (
                    ""
                  )}
                </InfoBoxTwo>
              )}
           {(userDetail.plan === "starter" || userDetail.plan === "starter_yearly") && (
              <InfoBoxTwo>
                <BoxContent className="plan">
                  <strong>My Current Plan</strong>
                </BoxContent>
                <BoxTitle>
                  {status === USER_SUBSCRIPTION.TRIAL
                    ? "Trial Subscription"
                    : status === USER_SUBSCRIPTION.ACTIVE && userDetail?.plan === "starter_yearly"
                    ? "Starter Yearly Subscription"
                    : status === USER_SUBSCRIPTION.ACTIVE
                    ? "Starter Subscription"
                    : status === USER_SUBSCRIPTION.PAST_DUE
                    ? "Past Due"
                    : "Not Subscribed"}
                </BoxTitle>
                {(status === USER_SUBSCRIPTION.TRIAL || status === USER_SUBSCRIPTION.ACTIVE) && (
                  <BoxContent>
                    {userDetail?.plan === "starter_yearly" ? (
                      <>
                        <PriceSpan>$97</PriceSpan> per year
                      </>
                    ) : (
                      <>
                        <PriceSpan>$12</PriceSpan> per month
                      </>
                    )}
                  </BoxContent>
                )}
                {status !== USER_SUBSCRIPTION.TRIAL && status !== USER_SUBSCRIPTION.ACTIVE && (
                  <BoxContent>
                    {userDetail?.plan === "starter_yearly" ? (
                      <>
                        <PriceSpan>$0</PriceSpan> per year
                      </>
                    ) : (
                      <>
                        <PriceSpan>$0</PriceSpan> per month
                      </>
                    )}
                  </BoxContent>
                )}
                <BoxContent style={{ margin: "-35px" }}>
                  <Suspense
                    fallback={
                      <div
                        style={{
                          minHeight: "100vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Loading...
                      </div>
                    }
                  >
                    <ContentListBox>
                      {[
                        "300 comments/month",
                        "1 emotion",
                        "Tune™ - short comments",
                        "GenuYou™ - personalized comments",
                        "Multi-language support",
                        "Comment grammar check",
                        "Standard support",
                        "Commenter academy",
                      ].map((text, index) => (
                        <ContentItem key={index}>
                          <CheckIcon src={checkIcon} />
                          <TextBoxs>{text}</TextBoxs>
                        </ContentItem>
                      ))}
                    </ContentListBox>
                  </Suspense>
                </BoxContent>
                {status === USER_SUBSCRIPTION.TRIAL && (
                  <BoxContent isButton>
                    <SubmitButton
                      onClick={userDetail?.plan === "starter_yearly" ? handleSubscribeYearly : handleSubscribe}
                    >
                      UPGRADE MY PLAN
                    </SubmitButton>
                  </BoxContent>
                )}
                {status !== USER_SUBSCRIPTION.TRIAL && status !== USER_SUBSCRIPTION.ACTIVE && (
                  <BoxContent isButton>
                    <SubmitButton
                      onClick={userDetail?.plan === "starter_yearly" ? handleSubscribeYearly : handleSubscribe}
                    >
                      UPGRADE MY PLAN
                    </SubmitButton>
                  </BoxContent>
                )}
              </InfoBoxTwo>
            )}

            {(userDetail.plan === "professional" || userDetail.plan === "professional_yearly") && (
              <InfoBoxTwo>
                <BoxContent className="plan">
                  <strong>My Current Plan</strong>
                </BoxContent>
                <BoxTitle>
                  {status === USER_SUBSCRIPTION.TRIAL
                    ? "Trial Subscription"
                    : ""}
                  {status === USER_SUBSCRIPTION.ACTIVE
                  ? userDetail?.plan === "professional_yearly"
                    ? "Professional Yearly Subscription"
                    : "Professional Subscription"
                  : ""}
                  {status === USER_SUBSCRIPTION.PAST_DUE ? "Past Due" : ""}
                  {status !== USER_SUBSCRIPTION.TRIAL &&
                    status !== USER_SUBSCRIPTION.ACTIVE &&
                    status !== USER_SUBSCRIPTION.PAST_DUE
                    ? "Not subscribed"
                    : ""}
                </BoxTitle>
                {status === USER_SUBSCRIPTION.TRIAL ||
                  status === USER_SUBSCRIPTION.ACTIVE ? (
                    <BoxContent>
                    {userDetail?.plan === "professional_yearly" ? (
                      <>
                        <PriceSpan>$149</PriceSpan> per year
                      </>
                    ) : (
                      <>
                        <PriceSpan>$17</PriceSpan> per month
                      </>
                    )}
                  </BoxContent>
                ) : (
                  ""
                )}
                {status !== USER_SUBSCRIPTION.TRIAL &&
                  status !== USER_SUBSCRIPTION.ACTIVE ? (
                    <BoxContent>
                    {userDetail?.plan === "professional_yearly" ? (
                      <>
                        <PriceSpan>$0</PriceSpan> per year
                      </>
                    ) : (
                      <>
                        <PriceSpan>$0</PriceSpan> per month
                      </>
                    )}
                  </BoxContent>
                ) : (
                  ""
                )}
                <BoxContent style={{ margin: "-35px" }}>
                  <Suspense
                    fallback={
                      <div
                        style={{
                          minHeight: "100vh",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        Loading...
                      </div>
                    }
                  >
                    {/* <StartGrowingList /> */}
                    <ContentListBox>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>900 comments/month </TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>
                          3 emotions (including Natural™ emotion).
                        </TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>5 commenting styles</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>
                          Full TextTune™ - short and long comments
                        </TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>GenuYou™ - comment personalization</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Multi-language support.</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>SmartReply+™- basic comment replies</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Grammar check of your comment</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Better comment suggestion</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Saved comments - up to 40.</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Engagement list - up to 40.</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Commenter academy</TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>
                          Video course: “Strategic commenting”
                        </TextBoxs>
                      </ContentItem>
                      <ContentItem>
                        <CheckIcon src={checkIcon} />
                        <TextBoxs>Priority support</TextBoxs>
                      </ContentItem>
                    </ContentListBox>
                  </Suspense>
                </BoxContent>
                {status === USER_SUBSCRIPTION.TRIAL ? (
                  <BoxContent isButton>
                    <SubmitButton onClick={handleUpgradePlan}>
                      UPGRADE MY PLAN
                    </SubmitButton>
                  </BoxContent>
                ) : (
                  ""
                )}
                {/* {status === USER_SUBSCRIPTION.TRIAL ||
                  status === USER_SUBSCRIPTION.ACTIVE ||
                  status === USER_SUBSCRIPTION.PAST_DUE ? (
                  <BoxContent isButton>
                    <SubmitButton
                      className={isLoadingOne ? "loading cancel" : "cancel"}
                      onClick={handleCancelSubscription}
                    >
                      {isLoadingOne ? <Loader /> : "CANCEL SUBSCRIPTION"}
                    </SubmitButton>
                  </BoxContent>
                ) : (
                  ""
                )} */}
                {status !== USER_SUBSCRIPTION.TRIAL &&
                  status !== USER_SUBSCRIPTION.ACTIVE ? (
                  <BoxContent isButton>
                    {/* <SubmitButton
                      className={isLoadingThree ? "loading" : ""}
                      onClick={handleSubscribe}
                    >
                      {isLoadingThree ? <Loader /> : "SUBSCRIBE"}
                    </SubmitButton> */}
                    <SubmitButton
                      onClick={userDetail?.plan === "professional_yearly" ? handleSubscribeProfesYearly : handleSubscribePro}
                    >
                      UPGRADE MY PLAN
                    </SubmitButton>
                  </BoxContent>
                ) : (
                  ""
                )}
              </InfoBoxTwo>
            )}
            <div className="responster-popup-overlay"></div>
            <div className="responster-popup">
              <button
                className="responster-close-survey-btn"
                onClick={() => closeModal()}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 24 24"
                  focusable="false"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill="none"
                    stroke="#000"
                    strokeWidth="2"
                    d="M3,3 L21,21 M3,21 L21,3"
                  ></path>
                </svg>
              </button>
              <iframe
                src="https://forms.responster.com/7f9632"
                id="survey"
                onLoad={() => closeDelay()}
                style={{ minHeight: "400px", minWidth: "400px" }}
                frameBorder="0"
              >
                {" "}
              </iframe>
            </div>
          </RightColumn>
        </TwoColumns>
      </MainContainer>
    </Wrapper >
  );
};

const Wrapper = styled.div`
  > div {
    max-width: 1130px;
  }
`;
const Checkoutbox = styled.div`
margin: 10px 0px 20px 0px;
> form
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 19px;
  line-height: 26px;
    input {
        width: 18px;
        height: 18px;
    }


`;

const Error = styled.div`
  color: red;
`;

const Success = styled.div`
  color: green;
`;

const BoxContent = styled.p`
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 26px;
  text-align: ${(props) => (props.isButton ? "center" : "left")};
  margin-top: ${(props) => (props.isButton ? "65px" : "0")};
  &.block {
    margin-top: 0;
  }
  &.plan {
    font-size: 1.2rem;
  }
`;

const PriceSpan = styled.span`
  font-family: "Space Grotesk";
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 48px;
  margin-bottom: -20px;
`;

const BoxTitle = styled.h3`
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 36px;
  text-transform: capitalize;
  margin-block-start: 0;
  margin-block-end: 1rem;
  @media only screen and (max-width: ${size.minWidthTablet}) {
    font-size: 27px;
  }
`;

const InfoBoxTwo = styled.div`
  background-color: var(--main-bg);
  color: var(--white);
  border-radius: 10px;
  padding: 33px 15px 30px;
  animation: image-fade-up 1.5s;
`;

const InfoBox = styled.div`
  background-color: var(--main-bg);
  color: var(--white);
  border-radius: 10px;
  padding: 33px 15px 30px;
  animation: image-fade-up 1.5s;
`;

const TwoColumns = styled.div`
  margin: 5rem 0;
  padding: 0 1rem;

  @media only screen and (min-width: ${size.minWidthTablet}) {
    display: flex;
  }
`;

const LeftColumn = styled.div`
  @media only screen and (min-width: ${size.minWidthTablet}) {
    flex: 4;
  }
`;
const RightColumn = styled.div`
  margin-left: 2rem;
  @media only screen and (min-width: ${size.minWidthTablet}) {
    flex: 3;
  }

  @media only screen and (max-width: ${size.minWidthTablet}) {
    margin-left: 0;
  }
`;

const SubmitButton = styled.a`
  background: #ff2400;
  border-radius: 500px;
  padding: 16px 60px;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  outline: 6px solid rgba(255, 36, 0, 0.33);
  transition: 0.4s;
  text-transform: capitalize;
  min-width: 100px;
  display: inline-block;
  &.block {
    display: block;
    color: white;
  }
  &.cancel {
    background: transparent;
    color: #fff;
    outline: 6px solid rgba(255, 255, 255, 0.33);
    &.block {
      background: #0050b2;
      outline: 6px solid rgba(0, 36, 255, 0.33);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.loading {
    background-color: #fedd00;
    outline: 6px solid rgba(254, 221, 0, 0.42);
    transition: 0.4s;
    padding: 16px 60px;
  }
  &:hover {
    transition: 0.4s;
    background: var(--white);
    color: #ff2400;
    cursor: pointer;
  }

  span {
    display: inline-block;
  }

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    padding: 12px 20px;
    text-align: center;
    outline: 3px solid rgba(255, 255, 255, 0.42);
    font-size: 16px;
    line-height: 25px;
  }
`;

const UpgradeButton = styled.a`
  background: #ff2400;
  border-radius: 500px;
  padding: 16px 60px;
  color: #fff;
  font-family: "Space Grotesk", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 30px;
  outline: 6px solid rgba(255, 36, 0, 0.33);
  transition: 0.4s;
  text-transform: capitalize;
  min-width: 100px;
  display: inline-block;
  margin-top: 20px;
  &.block {
    display: block;
    color: white;
  }
  &.cancel {
    background: transparent;
    color: #fff;
    outline: 6px solid rgba(255, 255, 255, 0.33);
    &.block {
      background: #0050b2;
      outline: 6px solid rgba(0, 36, 255, 0.33);
      &:hover {
        background-color: transparent;
      }
    }
  }
  &.loading {
    background-color: #fedd00;
    outline: 6px solid rgba(254, 221, 0, 0.42);
    transition: 0.4s;
    padding: 16px 60px;
  }
  &:hover {
    transition: 0.4s;
    background: var(--white);
    color: #ff2400;
    cursor: pointer;
  }

  span {
    display: inline-block;
  }

  @media only screen and (max-width: ${size.maxWidthTablet}) {
    padding: 12px 20px;
    text-align: center;
    outline: 3px solid rgba(255, 255, 255, 0.42);
    font-size: 16px;
    line-height: 25px;
  }
`;

const Planbox = styled.div`
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 0px 15px;
  @media only screen and (max-width: 991px) {
    flex-direction: column;
    padding: 0px;
  }
`;
const PlanboxOne = styled.div`
  max-width: 389px;
  width: 100%;
  height: max-content;
  border-radius: 45px;
  border: 8px solid #ebebeb;
  background: var(--w, #fff);
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    max-width: 326px;
    ${"" /* box-sizing: border-box; */}
  }
`;
const FreeplanPart = styled.div`
  border-radius: 32px 32px 0px 0px;
  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #d0e5ff 0%,
    #bfd3ec 0.01%,
    #dedede 48.92%,
    #dcecff 91.67%
  );
  & > h2 {
    color: #4b4b4b;
    font-family: Space Grotesk;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    /* padding: 41px 105px 55px 103px; */
    margin: 0px;
    text-align: center;
    min-height: 105px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const RedButtonclick = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: center;
`;
const NeeDed = styled.div`
  color: #333;
  text-align: center;
  font-family: Space Grotesk;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -0.8px;
  text-transform: capitalize;
  margin: 15px 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
`;

const PlanboxTwo = styled.div`
  border-radius: 45px;
  border: 8px solid #ebebeb;
  box-shadow: 0px 20px 56px 0px rgba(0, 0, 0, 0.16);
  max-width: 389px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  @media only screen and (max-width: 991px) {
    max-width: 89%;
    margin: auto;
  }
  @media only screen and (max-width: 1322px) {
    width: 326px;
    box-sizing: border-box;
    flex-shrink: 0;
  }
`;
const ProPlan = styled.div`
  border-radius: 40px 40px 0px 0px;

  background: radial-gradient(
    142.67% 120.19% at 91.93% 3.53%,
    #0050b2 0%,
    #2c281f 0.01%,
    #1f1b13 48.92%,
    #12100b 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${"" /* padding-right: 20px; */}

  >span{
    display:flex;
    text-align:center;
  }
`;
const Tag = styled.div`
  background: #ff2400;
  padding: 6px;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(37deg);
  position: absolute;
  top: 26px;
  width: 45%;
  right: -46px;
  text-align: center;
  font-size: 13px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  text-transform: uppercase;
  font-family: Space Grotesk;
  @media only screen and (max-width: 1323px) {
    top: 16px;
    width: 38%;
    right: -28px;
    font-size: 8px;
  }
  @media only screen and (max-width: 1200px) {
    position: absolute;
    top: 15px;
    width: 47%;
    right: -35px;
    text-align: center;
    font-size: 7px;
  }
  @media only screen and (max-width: 991px) {
    font-size: 13px;
    position: absolute;
    top: 30px;
    width: 200px;
    right: -46px;
    text-align: center;
  }

  @media only screen and (max-width: 480px) {
    font-size: 9px;
    top: 19px;
    width: 53%;
    text-align: center;
  }
`;
const CheckIcon = styled.img`
  width: 14px;
  height: 14px;
`;
const ContentList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px;
  @media only screen and (max-width: 480px) {
    padding: 15px;
  }
`;
const ContentListBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 76%;
  margin: auto;
  padding: 28px;
  @media only screen and (max-width: 480px) {
    padding: 15px;
  }
`;

const TextBoxs = styled.div`
  font-size: 24px;
  line-height: 134.52%;
  margin-bottom: 0.5rem;
  font-family: Space Grotesk;
  font-weight: 500;
  letter-spacing: 0em;

  color: #fff;
`;
const ContentItem = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;
const Texts = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  padding-bottom: 12px;
  text-align: center;
  color: #12100b;
`;
const Text = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #12100b;
`;
const TextBlue = styled.div`
  font-family: Space Grotesk;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #0050b2;
`;
const MonthPlan = styled.div`
  color: #000;
  font-family: Space Grotesk;
  font-size: 19.5px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  margin-top: 20px;
  text-align: center;
  > span {
    color: #000;
    text-align: center;
    font-family: Space Grotesk;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%; /* 30px */
    text-decoration-line: line-through;
  }
`;
const PerCup = styled.div`
  color: #000;
  text-align: center;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
const CancleAnytime = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 18px;
`;
const TextCancel = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;
const NewRedbtn = styled.div`
  ${"" /* margin-top: 65px; */}

  @media only screen and (max-width: 767px) {
    margin-top: 0px;
  }
`;

const ProPlanBlackTwo = styled.div`
  border-radius: 40px 40px 0px 0px;
  background: radial-gradient(
    96.02% 97.16% at 23.13% 29.46%,
    #0050b2 0%,
    #0a66c2 70.17%,
    #0050b2 100%
  );
  height: 119px;
  color: var(--w, #fff);
  font-family: Space Grotesk;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Toggle = styled.div`
    max-width: 1233px;
    display: flex;
    width: 100%;
    margin: auto;
    justify-content: center;

@media only screen and (max-width:767px){
  padding:0px;
  display:flex;
  margin-bottom:40px;
 

}
 .toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  width: max-content;
}

.toggle {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-label {
  font-size: 20px;
  font-weight: bold;
  color:#fff;

  @media only screen and (max-width:767px){
    font-size: 12px;
  }
}

`;

export default SettingsPage;
