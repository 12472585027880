import styled from "styled-components";
import before1 from "../../assets/logcutom.png";
import axios from "axios";
import { useState, useEffect, useRef } from "react";
import { validateSession } from "../../hooks/useValidateSession";
import { useNavigate } from "react-router-dom";
import { config } from "../../utils/config";
import { useGetStoredData } from "../../hooks/useGetStoreData";
import { fetchAPI } from "../../utils/fetch";

const Custom = () => {
  const maxComments = 10;
  const maxKeywords = 20;
  const maxCategories = 3;
  const maxStyles = 3;

  const navigate = useNavigate();
  const storage = localStorage.getItem("userData");
  const storageJson = JSON.parse(storage);
  // console.log('STORAGEJSON', storageJson);

  const [comments, setComments] = useState(["", "", "", "", ""]);
  const [newKeyword, setNewKeyword] = useState("");
  const [keywords, setKeywords] = useState([]);
  const [categories, setCategories] = useState([]);
  const [styles, setStyles] = useState([]);
  const [categoryInput, setCategoryInput] = useState("");
  const [styleInput, setStyleInput] = useState("");
  const [errorKeywords, setErrorKeywords] = useState("");
  const [errorCategories, setErrorCategories] = useState("");
  const [errorStyles, setErrorStyles] = useState("");
  const [promptText, setPromptText] = useState(""); // State to store the prompt text
  const [useCustomPrompt, setUseCustomPrompt] = useState(false); // State to Enable/diable custom prompt
  const [formError, setFormError] = useState(""); // State to store form validation error
  const [proMonthError, setProMonthError] = useState(""); // State to store form validation error
  const [highlightEmptyFields, setHighlightEmptyFields] = useState(false); // State to highlight empty fields
  const [isPromptGenerated, setIsPromptGenerated] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [saveButtonText, setSaveButtonText] = useState('Save');
  const [updatePromptButtonText, setUpdatePromptButtonText] = useState('Update prompt');
  const [saving, setSaving] = useState(false); // State to track saving process
  const [updating, setUpdating] = useState(false);

  const [sevenDayTrial, setSevenDayTrail] = useState(true)
  const errorRef = useRef(null)

  const shortCommentRef = useRef(null);
  const longCommentRef = useRef(null);

  const storedData = useGetStoredData();

  useEffect(() => {
    validateSession()
      .then((result) => {
        // console.log('VALIDATIONRESULT', result)
        if (result.success === false || result === false) {
          navigate("/unauthorized");
        }
      })
      .catch((error) => {
        navigate("/unauthorized");
      });
  }, [navigate, storageJson]);

  useEffect(() => {
    fetchAPI
      .get(
        `${config.urlPlatform}/api/v2/auth/user?email=${storageJson.email}`,
        {
          withCredentials: true,
        }
      )
      .then((result) => {
        if (result && result.status === 200) {
          if (shortCommentRef.current) shortCommentRef.current.value = result?.data?.shortCommentLength || '';
          if (longCommentRef.current) longCommentRef.current.value = result?.data?.longCommentLength || '';
          setSevenDayTrail(result?.data?.sevenDayTrial)
          setIsChecked(result?.data?.useCustomPrompt)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (storageJson && storageJson.comments) {
      setComments(storageJson.comments);
    }
    if (storageJson && storageJson.keywords) {
      setKeywords(storageJson.keywords);
    }
    if (storageJson && storageJson.categories) {
      setCategories(storageJson.categories);
    }
    if (storageJson && storageJson.styles) {
      setStyles(storageJson.styles);
    }
    if (storageJson && storageJson.prompt) {
      setPromptText(storageJson.prompt);
    }
  }, []);

  const validateForm = () => {
    // Validation checks
    const firstFiveComments = comments
      .slice(0, 5)
      .filter((comment) => comment.trim() !== "");
    if (firstFiveComments.length < 5) {
      setFormError("Please provide comments for the first 5 fields.");
      setHighlightEmptyFields(true);
      return false;
    }
    if (keywords.length < 1) {
      setFormError("Please provide at least 1 keyword.");
      setHighlightEmptyFields(true);
      return false;
    }
    if (categories.length < 1) {
      setFormError("Please provide at least 1 category.");
      setHighlightEmptyFields(true);
      return false;
    }
    if (styles.length < 1) {
      setFormError("Please provide at least 1 style.");
      setHighlightEmptyFields(true);
      return false;
    }
    return true;
  };

  const handleToggle = async () => {
    setFormError(""); // Reset form error state
    setHighlightEmptyFields(false); // Reset highlight state
    if (!validateForm()) {
      return;
    }
    if ((storageJson.plan !== 'proMonth' && storageJson.plan !== 'proMonth_yearly') && !sevenDayTrial) {
      setProMonthError('Subscribe to the Influencer plan to use this feature')
      if (errorRef.current) {
        errorRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
      return;
    }
    const newCheckedValue = !isChecked;
    setIsChecked(newCheckedValue);
    setUseCustomPrompt(newCheckedValue);
    try {
      const response = await axios.post(
        `${config.urlPlatform}/api/v2/prompt/update-toggle`,
        { useCustomPrompt: newCheckedValue, email: storageJson.email }
      );

      if (response.status === 200) {
        localStorage.setItem(
          "userData",
          JSON.stringify({
            ...storedData.userData,
            useCustomPrompt: newCheckedValue,
          })
        );
      }
      // console.log('Toggle value updated successfully');
    } catch (error) {
      console.error('Failed to update toggle value:', error);
    }
  };

  const handleAddComment = () => {
    if (comments.length < maxComments) {
      setComments([...comments, ""]);
    }
  };

  const handleCommentChange = (index, value) => {
    const newComments = [...comments];
    newComments[index] = value;
    setComments(newComments);
  };

  const handleKeywordChange = (e) => {
    setNewKeyword(e.target.value);
  };

  const handleAddKeyword = () => {
    if (newKeyword.trim() !== "") {
      if (keywords.length < maxKeywords) {
        setKeywords([...keywords, newKeyword.trim()]);
        setNewKeyword("");
        setErrorKeywords("");
      } else {
        setErrorKeywords(`You can only add up to ${maxKeywords} keywords.`);
      }
    } else {
      setErrorKeywords("Keyword cannot be empty.");
    }
  };

  const handleRemoveKeyword = (index) => {
    const newKeywords = [...keywords];
    newKeywords.splice(index, 1);
    setKeywords(newKeywords);
    setErrorKeywords("");
  };

  const handleCategoryChange = (e) => {
    setCategoryInput(e.target.value);
  };

  const handleAddCategory = () => {
    if (categoryInput.trim() === "") {
      setErrorCategories("Category cannot be empty.");
    } else if (categories.length >= maxCategories) {
      setErrorCategories(`You can only add up to ${maxCategories} categories.`);
    } else {
      setCategories([...categories, categoryInput]);
      setCategoryInput("");
      setErrorCategories("");
    }
  };

  const handleRemoveCategory = (index) => {
    const newCategories = categories.filter((_, i) => i !== index);
    setCategories(newCategories);
    setErrorCategories("");
  };

  const handleStyleChange = (e) => {
    setStyleInput(e.target.value);
  };

  const handleAddStyle = () => {
    if (styleInput.trim() === "") {
      setErrorStyles("Style cannot be empty.");
    } else if (styles.length >= maxStyles) {
      setErrorStyles(`You can only add up to ${maxStyles} styles.`);
    } else {
      setStyles([...styles, styleInput]);
      setStyleInput("");
      setErrorStyles("");
    }
  };

  const handleRemoveStyle = (index) => {
    const newStyles = styles.filter((_, i) => i !== index);
    setStyles(newStyles);
    setErrorStyles("");
  };

  const handlePromptTextChange = (event) => {
    setPromptText(event.target.value);
  };

  const handleSave = () => {
    setFormError(""); // Reset form error state

    if ((storageJson.plan !== 'proMonth' && storageJson.plan !== 'proMonth_yearly') && !sevenDayTrial) {
      setProMonthError('Subscribe to the Influencer plan to use this feature')
      // console.log('Please update to premium plan');
      return;
    }

    setHighlightEmptyFields(false); // Reset highlight state
    if (!validateForm()) {
      return;
    }


    const data = {
      comments: comments,
      keywords: keywords,
      categories: categories,
      styles: styles,
      email: storageJson.email,
      shortCommentLength: shortCommentRef.current?.value || null,
      longCommentLength: longCommentRef.current?.value || null,
    };
    setSaving(true);
    setSaveButtonText("Saving...");

    const endpoint = `${config.urlPlatform}/api/v2/prompt/create`;

    axios.post(endpoint, data)
      .then(response => {
        // console.log('Data saved successfully:', response.data);
        if (!response.data.error) {
          setPromptText(response.data.generatedInstruction);
        }
        // setIsPromptGenerated(true);
        setSaveButtonText('Saved')
        localStorage.setItem("userData", JSON.stringify({
          ...storedData.userData,
          comments: comments,
          keywords: keywords,
          categories: categories,
          styles: styles,
          prompt: promptText
        }));
      })
      .catch(error => {
        console.error('Error saving data:', error);
        // Handle error scenarios here
      })
      .finally(() => {
        setSaving(false); // Reset saving state
      });
  };

  const saveUserPrompt = () => {
    setUpdating(true); // Set saving state to true
    const data = {
      email: storageJson.email,
      prompt: promptText
    };

    // console.log("data >>>", data)

    const endpoint = `${config.urlPlatform}/api/v2/auth/user/updatePrompt`;

    axios.post(endpoint, data)
      .then(response => {
        // console.log('Data saved successfully:', response.data);
        if (!response.data.error) {
          setPromptText(response.data.prompt);
        }
        setUpdating(true);
        setUpdatePromptButtonText('Updated')
        localStorage.setItem("userData", JSON.stringify({
          ...storedData.userData,
          prompt: promptText
        }));
      })
      .catch(error => {
        console.error('Error saving data:', error);
        // Handle error scenarios here
      })
      .finally(() => {
        setUpdating(false); // Reset saving state
      });
  };

  return (
    <>
      <FluidContainer>
        <Container>
          <TitleToggleContainer>
            <Title>
              <h2 className="voicetitle">Personalize your voice</h2>
            </Title>
            {/* <Togglewraper className="Togglewrapper">
                       <ToggleContainer>
                            <ToggleSwitch>
                                <ToggleInput className="toggle_custom" type="checkbox" checked={isChecked} onChange={handleToggle} />
                                <Slider />
                            </ToggleSwitch>
                        </ToggleContainer>
                       </Togglewraper> */}
          </TitleToggleContainer>
          <Togglewraper className="Togglewrapper">
            <ToggleContainer>
              <ToggleSwitch>
                <ToggleInput className="toggle_custom" type="checkbox" checked={isChecked} onChange={handleToggle} />
                <Slider />
              </ToggleSwitch>
              {/* {proMonthError && <ErrorText>{proMonthError}</ErrorText>} */}
            </ToggleContainer>
          </Togglewraper>
          <ContainerWrapper>
            <ContainerLeftChildinner>
              <ContainerLeftChild>
                <ContainerChildNode>
                  <AncerTag>
                    <a
                      style={{ color: "white", fontSize: 20, marginBottom: 30 }}
                      href="https://youtu.be/012EYXFxknY"
                      target="_blank"
                    >
                      How to use this Feature?
                    </a>
                  </AncerTag>
                  <p>
                    1. Share at least 5 comments that represent your voice the
                    best below:
                  </p>
                  <MainWrapper>
                    {comments.map((comment, index) => (
                      <CommentWrapper
                        key={index}
                        className={`comment-${index + 1}`}
                      >
                        <p>Comment #{index + 1}</p>
                        <textarea
                          className={
                            highlightEmptyFields &&
                              comment.trim() === "" &&
                              index < 5
                              ? "highlight-error"
                              : ""
                          }
                          placeholder={`Comment #${index + 1}`}
                          value={comment}
                          onChange={(e) =>
                            handleCommentChange(index, e.target.value)
                          }
                        ></textarea>
                      </CommentWrapper>
                    ))}
                  </MainWrapper>
                  {comments.length < maxComments && (
                    <ButtonAdd onClick={handleAddComment}>
                      + Add more Comments
                    </ButtonAdd>
                  )}
                </ContainerChildNode>
                <ContainerChildNode>
                  <p>
                    2. Write the words that you mostly use in your comments
                    (write at least 1)
                  </p>
                  <MainWrapperAdd>
                    <CommentWrapperAdd>
                      <CommentwrapperNode>
                        <textarea
                          className={
                            highlightEmptyFields && keywords.length < 1
                              ? "highlight-error"
                              : ""
                          }
                          placeholder="E.g....marvelous, valuable, epic...."
                          value={newKeyword}
                          onChange={handleKeywordChange}
                        ></textarea>
                        <button onClick={handleAddKeyword}>Add</button>
                      </CommentwrapperNode>
                      {errorKeywords && <ErrorText>{errorKeywords}</ErrorText>}
                      <AdditionWrapper>
                        <p>Selected words</p>
                        <AdditonalwrapperNode>
                          {keywords.map((keyword, index) => (
                            <ButtonWord key={index}>
                              {keyword}
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleRemoveKeyword(index)}
                              >
                                <path
                                  d="M12 0.5L1 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1 0.5L12 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </ButtonWord>
                          ))}
                        </AdditonalwrapperNode>
                      </AdditionWrapper>
                    </CommentWrapperAdd>
                  </MainWrapperAdd>
                </ContainerChildNode>
                <ContainerChildNode>
                  <p>3. How do you want the AI to speak like:</p>
                  <MainWrapperAdd>
                    <CommentWrapperAdd>
                      <CommentwrapperNode>
                        <textarea
                          className={
                            highlightEmptyFields && categories.length < 1
                              ? "highlight-error"
                              : ""
                          }
                          placeholder="E.g...coach, consultant..."
                          value={categoryInput}
                          onChange={handleCategoryChange}
                        />
                        <button onClick={handleAddCategory}>Add</button>
                      </CommentwrapperNode>
                      {errorCategories && (
                        <ErrorText>{errorCategories}</ErrorText>
                      )}
                      <AdditionWrapper>
                        <p>Selected category</p>
                        <AdditonalwrapperNode>
                          {categories.map((category, index) => (
                            <ButtonWord
                              key={index}
                              onClick={() => handleRemoveCategory(index)}
                            >
                              {category}
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 0.5L1 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1 0.5L12 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </ButtonWord>
                          ))}
                        </AdditonalwrapperNode>
                      </AdditionWrapper>
                    </CommentWrapperAdd>
                  </MainWrapperAdd>
                </ContainerChildNode>
                <ContainerChildNode>
                  <p>4. How would you describe your commenting style as:</p>
                  <MainWrapperAdd>
                    <CommentWrapperAdd>
                      <CommentwrapperNode>
                        <textarea
                          className={
                            highlightEmptyFields && styles.length < 1
                              ? "highlight-error"
                              : ""
                          }
                          placeholder="E.g...funny, creative..."
                          value={styleInput}
                          onChange={handleStyleChange}
                        ></textarea>
                        <button onClick={handleAddStyle}>Add</button>
                      </CommentwrapperNode>
                      {errorStyles && <ErrorText ref={errorRef}>{errorStyles}</ErrorText>}
                      <AdditionWrapper>
                        <p>Selected style</p>
                        <AdditonalwrapperNode>
                          {styles.map((style, index) => (
                            <ButtonWord key={index}>
                              {style}
                              <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => handleRemoveStyle(index)}
                              >
                                <path
                                  d="M12 0.5L1 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M1 0.5L12 11.5"
                                  stroke="white"
                                  strokeLinecap="round"
                                />
                              </svg>
                            </ButtonWord>
                          ))}
                        </AdditonalwrapperNode>
                      </AdditionWrapper>
                    </CommentWrapperAdd>
                  </MainWrapperAdd>
                </ContainerChildNode>
                <ContainerChildNode>
                  <p>5. What should be the maximum length of your comments?</p>
                  <MainWrapperAdd>
                    <OptionalWrapper>
                      <OptionalLeft>
                        <OptionalLabel>short </OptionalLabel>
                        <input ref={shortCommentRef} className="" placeholder="E.g...30" />
                      </OptionalLeft>
                      <OptionalLeft>
                        <OptionalLabel>long </OptionalLabel>
                        <input ref={longCommentRef} className="" placeholder="E.g...30" />
                      </OptionalLeft>
                    </OptionalWrapper>
                  </MainWrapperAdd>
                </ContainerChildNode>
              </ContainerLeftChild>
              <ButtonSubmit id="button_hover" onClick={handleSave}>
                {saving ? "Saving..." : saveButtonText}
              </ButtonSubmit>
              {proMonthError && <ErrorText ref={errorRef}>{proMonthError}</ErrorText>}
              {formError && <ErrorText ref={errorRef}>{formError}</ErrorText>}
            </ContainerLeftChildinner>
            <ContainerRightChild>
              <RightBox>
                <b>Your Prompt</b>
                <textarea
                  placeholder="(Important: All your comments and words provided will be added to the prompt below when generating your comments so the AI can have even better understanding of your voice)"
                  value={promptText}
                  onChange={handlePromptTextChange}
                />
                <ButtonSubmit
                  style={{ backgroundColor: '#0152b3', color: 'white' }}
                  id="button_hover"
                  onClick={() => { saveUserPrompt() }}
                >
                  {updating ? "Updating..." : updatePromptButtonText}
                </ButtonSubmit>
                {/* <button onClick={saveUserPrompt}>Update prompt</button> */}
              </RightBox>
              <NoteParagraph>
                Note: The prompt above is only part of what our AI will use. The rest will come from analyzing your details from points 1 and 2.
              </NoteParagraph>
            </ContainerRightChild>
          </ContainerWrapper>
        </Container>
      </FluidContainer>
    </>
  );
};

const OptionalWrapper = styled.div`
  display:flex;
  gap:15px;
  justify-content: space-between;

  @media only screen and (max-width:767px){
    flex-direction: column;
  }
`;
const OptionalLeft = styled.div`
    width: max-content;
    display: flex;
    align-items: center;
    gap: 10px;

    @media only screen and (max-width:767px){
        flex-direction: column;
        align-items: start;
        width: 100%;
    }

    > input {
      max-width: 161px;
      height: 52px;
      top: 1441px;
      left: 246px;
      gap: 0px;
      border-radius: 12px;
      opacity: 0px;
      border: 0;
      padding: 10px;
      box-sizing: border-box;
      color: #0152b3;
      font-size: 18px;
      font-weight: 400;
      line-height: 22.97px;
      text-align: left;
      @media only screen and (max-width:767px){
         width: 100%;
         max-width:100%;
         font-size: 16px;
      }
      ::placeholder {
        color: #0152b3;
        font-size: 18px;
        font-weight: 400;
        line-height: 22.97px;
        text-align: left;
        @media only screen and (max-width:767px){
          font-size: 16px;
        }
      }
      :focus{
        outline:0px;
      }
   
    }
    
`;
const OptionalLabel = styled.div`
    font-family: Space Grotesk;
    font-size: 24px;
    font-weight: 500;
    line-height: 30.97px;
    letter-spacing: 0.13em;
    text-align: left;
    color: #fff;
    text-transform: uppercase;
    @media only screen and (max-width:767px){
      font-size: 15px;
      line-height:normal;
    }
`;

const NoteParagraph = styled.p`
  display: flex;
  align-items: center;
  justify-content: space-between; // Ensure space between the title and toggle
  width: 100%; // Adjust based on your layout needs
  padding: 4px; // Add padding for spacing
  color: white; // Change text color to white
`;

const TitleToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between; // Ensure space between the title and toggle
  width: 100%; // Adjust based on your layout needs
  padding: 20px; // Add padding for spacing
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 10vh; // Center the toggle in the middle of the viewport
   padding: 0px 20px
`;

// Define the styled toggle switch component
const ToggleSwitch = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
 
`;

// Define the styled input (hidden)
const ToggleInput = styled.input`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + span {
    background-color: #2196f3; // Blue color for the slider when checked
  }

  &:checked + span:before {
    transform: translateX(26px);
  }
`;

// Define the styled slider
const Slider = styled.span`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;

  &:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
  }
`
const Title = styled.div`
  color: #fff;
  text-align: center;
  font-family: "Space Grotesk";
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: 134.1%;
  letter-spacing: -1.44px;
  margin-bottom: 30px;
  margin: auto;

  >h2
  margin-bottom: 0px;


  @media only screen and (max-width: 1023px) {
    font-size: 26px;
  }
`;
const FluidContainer = styled.div`
  background: #0152b3;
  width: 100%;
  height: 100%;
`;
const Container = styled.div`
  max-width: 1200px;
  padding: 70px 15px;
  margin: auto;
  width: 100%;
  box-sizing: border-box;
  @media only screen and (max-width: 1023px) {
    padding: 30px 15px;
  }
`;
const ContainerWrapper = styled.div`
  display: flex;
  gap: 100px;
  justify-content: space-between;
  @media only screen and (max-width: 1023px) {
    gap: 40px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;
const ContainerLeftChild = styled.div`
  > div:last-child {
    border: 0px solid #fff;
  }
  @media only screen and (max-width: 1023px) {
    > div:last-child {
      margin-bottom: 0px;
    }
  }
`;
const ContainerChildNode = styled.div`
  padding-bottom: 15px;
  border-bottom: 2px solid #fff;
  margin-bottom: 20px;

  > p {
    font-family: Space Grotesk;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.81px;
    text-align: left;
    color: #fff;
    margin: 0px;
    max-width: 570px;
  }
 
 @media only screen and (max-width:1024px){
  :last-child{
   margin-bottom:15px !important;
  }
 }
`;
const CommentWrapper = styled.div`
  > p {
    font-family: Space Grotesk;
    font-size: 20px;
    font-weight: 500;
    line-height: 25.81px;
    text-align: left;
    color: #fff;
    margin: 0px;
  }
  > textarea {
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 400;
    line-height: 22.97px;
    text-align: left;
    background: #fff;
    width: 100%;
    border: none;
    border-radius: 12px;
    color: #0152b3;
    padding: 20px 30px;
    box-sizing: border-box;
    resize: none;
    height: auto;
    margin-top: 10px;
  }
  @media only screen and (max-width: 1023px) {
    font-size: 16px;
  }
  .highlight-error {
    border: 3px solid red;
  }
`;

const AncerTag = styled.div`
  margin-bottom: 20px;
`;
const ButtonAdd = styled.div`
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.81px;
  text-align: right;
  color: #fff;
  text-decoration: underline;
  margin-top: 15px;
  cursor: pointer;
`;
const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 22px;
  max-height: 756px;
  overflow: auto;
  padding: 0 4px;
  margin-top:30px;
  ::scrollbar {
  }
`;
const MainWrapperAdd = styled.div`
  margin-top: 25px;
`;

const Togglewraper = styled.div``;
const CommentWrapperAdd = styled.div``;
const CommentwrapperNode = styled.div`
  position: relative;
  > textarea {
    font-family: "Space Grotesk";
    font-size: 18px;
    font-weight: 400;
    line-height: 22.97px;
    text-align: left;
    background: rgb(255, 255, 255);
    width: 695px;
    border: none;
    border-radius: 12px;
    color: rgb(1, 82, 179);
    padding: 15px;
    box-sizing: border-box;
    resize: none;
    display: flex;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: 52px;
    overflow: hidden;

    ::placeholder {
      color: #0152b3;
    }

    @media only screen and (max-width: 1023px) {
      font-size: 16px;
    }
  }
  > button {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 15px;
    margin: auto;
    background: none;
    border: none;
    cursor: pointer;
    font-family: Space Grotesk;
    font-size: 20px;
    font-weight: 700;
    line-height: 25.81px;
    padding: 0;
    color: #000000;
  }
  .highlight-error {
    border: 3px solid red;
  }
`;

const AdditionWrapper = styled.div`
  margin-top: 25px;
  > p {
    margin: 0;
    font-family: Space Grotesk;
    font-size: 15px;
    font-weight: 500;
    line-height: 19.36px;
    letter-spacing: 0.13em;
    text-align: left;
    color: #ffff;
  }
`;
const AdditonalwrapperNode = styled.div`
  margin: 20px 0;
  display: flex;
  gap: 15px;
  max-height: 120px;
  overflow: auto;
  flex-wrap: wrap;
`;
const ButtonWord = styled.div`
  border: 1px solid #fff;
  padding: 10px 20px;
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Space Grotesk;
  font-size: 20px;
  font-weight: 500;
  line-height: 25.81px;
  text-align: left;
`;
const ButtonSubmit = styled.div`
  max-width: 360px;
  height: 50px;
  border-radius: 25px;
  box-sizing: border-box;
  background: #fff;
  color: #0152b3;
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: Space Grotesk;
  font-size: 18px;
  font-weight: 700;
  line-height: 17.5px;
  align-items: center;
  margin: auto;
  cursor: pointer;
`;

const ContainerLeftChildinner = styled.div``;
const ContainerRightChild = styled.div`
  max-width: 470px;
  width: 100%;
`;
const LogoImage = styled.img`
  max-width: 280px;
  margin: auto;
  display: block;
  margin-bottom: 10px;
`;
const RightBox = styled.div`
  background: #fff;
  border-radius: 27px;
  padding: 25px 20px;
  min-height: 600px;
  position: relative;
  > b {
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 700;
    line-height: 22.97px;
    text-align: left;
    color: #000;
    margin: 0px;
  }
  > textarea {
    font-family: Space Grotesk;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.42px;
    text-align: left;
    color: #343434;
    margin: 20px 0 0px;
    display: block;
    width: 100%;
    border: none;
    height: 470px; 
    resize: none;

    :focus-visible {
      outline: 0px;
    }
  }
  > button {
    position: absolute;
    bottom: 25px;
    left: 0;
    right: 0;
    width: max-content;
    margin: auto;
    font-family: Space Grotesk;
    font-size: 18px;
    font-weight: 400;
    line-height: 17.5px;
    text-align: left;
    border: 0px;
    border-radius: 50px;
    padding: 13px 20px;
    color: #fff;
    background: #0152b3;
    cursor: pointer;
  }
`;
// const ErrorText = styled.p`
//   color: red;
//   margin-top: 5px;
// `;
const ErrorText = styled.p`
  color: #EE4B2B	;  /* Red color for errors */
  margin: 8px 0 8px 60px;  /* Vertical spacing and left margin */
  font-size: 18px; /* Increased font size */
  line-height: 1.5; /* Line height for readability */
  font-weight: 500; /* Semi-bold for emphasis */
`;
const highlightErrorStyle = {
  border: "1px solid red",
  backgroundColor: "#ffefef",
};

const textareaStyle = (highlightEmptyFields, comment, index) => {
  return highlightEmptyFields && comment.trim() === "" && index < 5
    ? highlightErrorStyle
    : {};
};

export default Custom;