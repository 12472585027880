import styled from "styled-components";
import Triangle from "../assets/playbutton.svg"
import { useState } from "react";
import { useEffect, useRef } from "react";
import Confetti from "react-confetti";
import { fetchAPI } from "../utils/fetch";
import { config } from "../utils/config";
import FirstStepBadge from "../assets/firstStepBadge.png";
import ReadyToCommentBadge from "../assets/readyToCommentBadge.png";
import YouTube from "react-youtube";

let currentVideoRef = null; // Shared reference for the currently playing video

const Videotutorial = ({ img, video = "", idx }) => {
  const [start, setStart] = useState(false); // Tracks whether the video is playing
  const [showConfetti, setShowConfetti] = useState(false);
  const [showCongrate, setShowCongrate] = useState(false);
  const [congrateModal, setCongrateModal] = useState(FirstStepBadge);
  const videoRef = useRef(null);

  const [opts, setOpts] = useState({
    playerVars: {
      controls: 0,
      modestbranding: 1,
      clipboardWrite: 1,
      encryptedMedia: 1,
      gyroscope: 1,
      pictureInPicture: 1,
      webShare: 1,
    },
  });

  const _onReady = (e) => {
    videoRef.current = e.target;
  };

  const handleStart = () => {
    // Pause the currently playing video if it's not this one
    if (currentVideoRef && currentVideoRef !== videoRef.current) {
      currentVideoRef.pauseVideo();
    }

    // Set the current video reference and toggle play state
    currentVideoRef = videoRef.current;

    if (start) {
      videoRef.current?.pauseVideo();
    } else {
      videoRef.current?.playVideo();
    }

    setStart(!start);
  };

  const handleStateChange = (event) => {
    const playerState = event.data;

    // If the video is paused (state === 2), show the play button and image
    if (playerState === 2) {
      setStart(false);
    }
    // If the video is playing (state === 1), hide the play button and image
    else if (playerState === 1) {
      setStart(true);
    }
  };

  useEffect(() => {
    // Dynamically update options based on `start` state
    setOpts((prev) => ({
      ...prev,
      playerVars: { ...prev.playerVars, autoplay: start ? 1 : 0 },
    }));
  }, [start]);

  return (
    <VideoContainer>
      {showConfetti && (
        <>
          <Modal
            onClick={() => {
              setShowCongrate(false);
              setShowConfetti(false);
            }}
          >
            {showCongrate && (
              <CongrateImg src={congrateModal} className="congrate-img" />
            )}
          </Modal>
          <Confetti
            style={{ position: "fixed", zIndex: 99 }}
            width={window.innerWidth}
            height={window.innerHeight}
            gravity={0.3}
            friction={0.99}
            wind={0}
            run={true}
            recycle={true}
          />
        </>
      )}
      {video && video?.split("embed/")[1]?.split("?")[0] ? (
        <YouTube
          videoId={video?.split("embed/")[1]?.split("?")[0]}
          containerClassName="embed embed-youtube"
          onReady={_onReady}
          opts={opts}
          onStateChange={handleStateChange} // Listen for video state changes
        />
      ) : (
        ""
      )}
      <Image src={img} className={start ? "hide" : ""} />
      <VideoButton
        className={start ? "hide" : ""}
        onClick={handleStart}
      >
        <PlayButton>
          <PlayButtonInnercircle>
            <PlayTriangle src={Triangle} />
          </PlayButtonInnercircle>
        </PlayButton>
      </VideoButton>
    </VideoContainer>
  );
};
  

const VideoContainer = styled.div`
    position: relative;
    iframe {
        position:absolute;
        border-radius: 10px;
        top:0;
        left:0;
        width:100%;
        height:100%;
        z-index: 3;
    }
    iframe.show {
        z-index: 10;
    }
`;

const VideoButton = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -45px;
    margin-top: -45px;
    z-index: 20;
    &.hide {
        z-index: -1;
    }
`;

const Image = styled.img`
    width: 100%;
    border-radius: 10px;
    visibility: hidden;
    &.hide {
        z-index: -1;
    }
`;

const PlayButton = styled.div`
    width: 90px;
    height: 90px;
    background:rgba(255, 255, 255, 0.38);
    backdrop-filter: blur(1.5px);
    border-radius: 50%;
    transition: .7s;
    position:relative;
    &:hover {
        background:rgba(255, 255, 255, 1);
        transition: .7s;
        cursor: pointer;
        > div {
            width:90px;
            height: 90px;
           
            transition: .7s;
            > img {
                transform: scale(1.2);
                transition: .7s;
            }
        }
    }
    @media only screen and (max-width: 991px) {
        width:70px;
        height:70px;
      
    }
`;

const PlayButtonInnercircle = styled.div`
    position: absolute;
    top: 0%;
    left: 0%;
    bottom:0;
    right:0;
    margin:auto;
    width: 70px;
    height: 70px;
    background: radial-gradient(69.64% 50.36% at 46.88% 49.64%, #0A66C2 0%, #0A66C2 0%, #1D9BF0 100%);
    border-radius: 50%;
    transition: .7s;
    @media only screen and (max-width: 991px) {
        width: 50px;
        height: 50px;
       
    }
`;

const PlayTriangle = styled.img`
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -11px;
    margin-top: -13px;
    transition: .7s;
`;
const Modal = styled.div`
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`
const CongrateImg = styled.img`
    width: 550px;
`
export default Videotutorial;