// import dotenv from 'dotenv';
// dotenv.config();
export const config = {
  urlPlatform: "https://commenter.ai",
  urlCommenter: "https://gpt3.socialpostmate.info/gpt3",

  /* Local link  */
  // urlPlatform: "http://localhost:3012",
  // urlCommenter: "https://commenter.ai/gpt3"
};

export const urlPaths = {
  homepage: "/testnew",
  comingsoon: "/new",
  comingsoon2: "/short",
  homenew: "/",
  commenterhome: "/partners",
  thankYouPage: "/thank-you",
  login: "/login",
  register: "/register",
  dashboardComingSoon: "/crm",
  proplan: "/proPlan",
  professional: "/professional",
  blackFriday: "/BlackWednesday",
  yearlyPlan: "/YearlyPlan",
  successRegister: "/success-register",
  requestNewPassword: "/request-new-password",
  newPassword: "/new-password",
  activateAccount: "/activate-account",
  unauthorized: "/unauthorized",
  sessionExpired: "/session-expired",
  platform: "/platform",
  engagement: "/engagement",
  marketplace: "/marketplace",
  contactUs: "/contact-us",
  settings: "/settings",
  getComments: "/get-comments",
  achievements: "/achievements",
  requestFeature: "/request-feature",
  aboutUs: "/about-us",
  results: "/results",
  tutorial: "/tutorials",
  custom: "/custom",
  time: "/time",
  pricing: "/pricing",
  successSubscription: "/success-subscription",
  privacyPolicy: "/privacy",
  termsOfService: "/terms-conditions",
  checkoutRedirect: "/checkout",
  surveyFinish: "/survey",
  savedComments: "/my-comments",
  finalStep: "/login-extension",
};

export const USER_SUBSCRIPTION = {
  TRIAL: "trialing",
  ACTIVE: "active",
  INACTIVE: "inactive",
  CANCELED: "canceled",
};
