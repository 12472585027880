import React from "react";
import { Routes, Route } from "react-router-dom";

import GlobalStyle from "./globalStyles";
import { urlPaths } from "./utils/config";

import LoginPage from "./pages/Login";
import RegisterPage from "./pages/RegisterOne";
import MainPage from "./pages/platform/Main";
import RequestNewPasswordPage from "./pages/RequestNewPassword";
import NewPasswordPage from "./pages/NewPassword";
import SettingsPage from "./pages/platform/Settings";
import GetCommentsPage from "./pages/platform/GetComments";
import Custom from "./pages/platform/Custom";

import AchievementsPage from "./pages/platform/Achievements";
import RequestAFeaturePage from "./pages/platform/RequestAFeature";
import DashboardComingSoon from "./pages/platform/DashboardComingSoon";
import Unauthorized from "./pages/Unauthorized";
import SessionExpired from "./pages/SessionExpired";
import SuccessSubscriptionPage from "./pages/platform/SuccessSubscription";
import Homepage from "./pages/sales/Homepage";
// import ComingSoon from "./pages/sales/ComingSoon";
import ComingSoon2 from "./pages/sales/ComingSoon2";
import ComingSoon from "./pages/sales/ComingSoon";
// import ComingSoon2 from "./pages/sales/ComingSoon2";
import AboutUs from "./pages/sales/AboutUs";
import Results from "./pages/sales/Results";

import Time from "./pages/Time";
import Pricing from "./pages/sales/Pricing";
import PrivacyPolicyPage from "./pages/sales/PrivacyPolicy";
import TermsPage from "./pages/sales/TermsOfService";
import CheckoutRedirectPage from "./pages/CheckoutRedirect";
import ContactPage from "./pages/platform/Contact";
import SalesPages from "./layout/SalesPages";
import SuccessRegisterPage from "./pages/SuccessRegister";
import EngagementList from "./layout/EngagementList";
import Marketplace from "./layout/Marketplace";
import ExtensionLoginPage from "./pages/ExtensionLoginPage";
import HomeNew from "./pages/sales/HomeNew";
import Commenterhome from "./pages/sales/Commenterhome";
import ThankYouPage from "./pages/sales/thankYouPage";
import PublicPages from "./layout/PublicPages";
import MainPages from "./layout/MainPage";
import NotFoundPage from "./pages/NotFound";
import SurveySuccess from "./pages/SurveySuccess";
import MyCommentList from "./layout/MyCommentList";
import ProPlan from "./layout/ProPlan";
import YearlyPlan from "./layout/YearlyPlan";
import BlackFriday from "./layout/BlackFriday";
import Professional from "./layout/Professional";
import { useEffect, useRef } from "react";
import Tutorial from "./pages/platform/Tutorial";

function App() {
  const scriptRef = useRef(null);
  useEffect(() => {
    // Load the Tidio script
    const timer = setTimeout(() => {
      scriptRef.current = document.createElement('script');
      scriptRef.current.src = "//code.tidio.co/zidoazq0yjqcnntterntgfgl8fgcwnxp.js";
      document.body.appendChild(scriptRef.current);
    }, 5000); // Delay of 5 seconds

    return () => {
      clearTimeout(timer); // Cleanup the timer
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current); // Cleanup the script if necessary
      }
    };
  }, []);
  return (
    <div className="App">
      <GlobalStyle />
      <Routes>
        <Route exact path={urlPaths.login} element={<LoginPage />} />
        <Route exact path={urlPaths.time} element={<Time />} />
        <Route
          exact
          path={urlPaths.successRegister}
          element={<SuccessRegisterPage />}
        />
        <Route
          exact
          path={urlPaths.finalStep}
          element={<ExtensionLoginPage />}
        />
        <Route
          path="/health"
          element={<h3>Hey there! The App is Healthy!</h3>}
        />
        <Route path="/*" element={<NotFoundPage />} />
        <Route element={<SalesPages />}>
          <Route exact path={urlPaths.homepage} element={<Homepage />} />
          <Route exact path={urlPaths.aboutUs} element={<AboutUs />} />
          <Route exact path={urlPaths.pricing} element={<Pricing />} />
          <Route exact path={urlPaths.results} element={<Results />} />
          

          <Route
            exact
            path={urlPaths.privacyPolicy}
            element={<PrivacyPolicyPage />}
          />
          <Route exact path={urlPaths.termsOfService} element={<TermsPage />} />
          <Route exact path={urlPaths.contactUs} element={<ContactPage />} />
          {/* <Route exact path={urlPaths.comingsoon} element={<ComingSoon />} /> */}
          <Route exact path={urlPaths.comingsoon2} element={<ComingSoon2 />} />
          <Route exact path={urlPaths.comingsoon} element={<ComingSoon />} />
          <Route exact path={urlPaths.homenew} element={<HomeNew />} />
          <Route exact path={urlPaths.commenterhome} element={<Commenterhome />} />
          <Route exact path={urlPaths.thankYouPage} element={<ThankYouPage />} />
          {/* <Route exact path={urlPaths.comingsoon2} element={<ComingSoon2 />} /> */}
        </Route>
        <Route element={<PublicPages />}>
          <Route exact path={urlPaths.register} element={<RegisterPage />} />
          <Route
            exact
            path={urlPaths.requestNewPassword}
            element={<RequestNewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.newPassword}
            element={<NewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.unauthorized}
            element={<Unauthorized />}
          />
          <Route
            exact
            path={urlPaths.sessionExpired}
            element={<SessionExpired />}
          />
        </Route>
        <Route element={<ProPlan />}>
          <Route
            exact
            path={urlPaths.proplan + "/:email"}
            element={<ProPlan />}
          />
          <Route
            exact
            path={urlPaths.requestNewPassword}
            element={<RequestNewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.newPassword}
            element={<NewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.unauthorized}
            element={<Unauthorized />}
          />
          <Route
            exact
            path={urlPaths.sessionExpired}
            element={<SessionExpired />}
          />
        </Route>
        <Route element={<Professional />}>
          <Route
            exact
            path={urlPaths.professional}
            element={<Professional />}
          />
          <Route
            exact
            path={urlPaths.requestNewPassword}
            element={<RequestNewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.newPassword}
            element={<NewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.unauthorized}
            element={<Unauthorized />}
          />
          <Route
            exact
            path={urlPaths.sessionExpired}
            element={<SessionExpired />}
          />
        </Route>
        <Route element={<BlackFriday />}>
          <Route exact path={urlPaths.blackFriday} element={<BlackFriday />} />
          <Route
            exact
            path={urlPaths.requestNewPassword}
            element={<RequestNewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.newPassword}
            element={<NewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.unauthorized}
            element={<Unauthorized />}
          />
          <Route
            exact
            path={urlPaths.sessionExpired}
            element={<SessionExpired />}
          />
        </Route>
        <Route element={<YearlyPlan />}>
          <Route exact path={urlPaths.yearlyPlan} element={<YearlyPlan />} />
          <Route
            exact
            path={urlPaths.requestNewPassword}
            element={<RequestNewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.newPassword}
            element={<NewPasswordPage />}
          />
          <Route
            exact
            path={urlPaths.unauthorized}
            element={<Unauthorized />}
          />
          <Route
            exact
            path={urlPaths.sessionExpired}
            element={<SessionExpired />}
          />
        </Route>
        <Route element={<MainPages />}>
          <Route exact path={urlPaths.platform} element={<MainPage />} />
          <Route exact path={urlPaths.settings} element={<SettingsPage />} />
          <Route
            exact
            path={urlPaths.getComments}
            element={<GetCommentsPage />}
          />
          <Route exact path={urlPaths.custom} element={<Custom />} />
          <Route exact path={urlPaths.tutorial} element={<Tutorial />} />
          <Route
            exact
            path={urlPaths.achievements}
            element={<AchievementsPage />}
          />
          <Route
            exact
            path={urlPaths.requestFeature}
            element={<RequestAFeaturePage />}
          />
          <Route
            exact
            path={urlPaths.dashboardComingSoon}
            element={<DashboardComingSoon />}
          />
          <Route
            exact
            path={urlPaths.successSubscription}
            element={<SuccessSubscriptionPage />}
          />
          <Route
            exact
            path={urlPaths.checkoutRedirect}
            element={<CheckoutRedirectPage />}
          />
          <Route
            exact
            path={urlPaths.engagement}
            element={<EngagementList />}
          />
          <Route
            exact
            path={urlPaths.marketplace}
            element={<Marketplace />}
          />
          <Route
            exact
            path={urlPaths.savedComments}
            element={<MyCommentList />}
          />
        </Route>
        <Route exact path={urlPaths.surveyFinish} element={<SurveySuccess />} />
      </Routes>
    </div>
  );
}

export default App;
